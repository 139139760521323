import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Column,
  MainHeading,
  MainHeading_nail,
  Row,
  TextWrapper,
} from "../../../globalStyles";
import { BiPlayCircle } from "react-icons/bi";
import {
  HeroSection,
  ButtonContainer,
  Hero799TextWrapper,
} from "./Hero799Styles";
import "./textbg.css";

const Hero799 = () => {
  return (
    <HeroSection id="hero">
      <Row justify="center" align="center" height="100%" padding="2rem">
        <Column align="center" mb="0%">
          <div class="text-container1">
            <Hero799TextWrapper>
              <MainHeading_nail>Website $799</MainHeading_nail>
            </Hero799TextWrapper>
          </div>
          <TextWrapper
            color="white"
            size="clamp(1rem,3vw,1.3rem)"
            margin="0 0 2rem"
            lineHeight="1.1rem"
            maxWidth="480px"
            align="center"
          >
            A range of impressive features that make website fast, secure, and
            seamlessly integrated with popular Facebook, Instagram, Google, and
            Booking.
          </TextWrapper>
          <ButtonContainer>
            <Link to={"/sign-up"}>
              <a
                target="_blank"
                href={"#"}
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                <div className="heroneon">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Get it now
                </div>
              </a>
            </Link>
            {/* <Button big fontBig>
							<BiPlayCircle size="2.3rem" /> Download
						</Button> */}
          </ButtonContainer>
        </Column>
      </Row>
    </HeroSection>
  );
};

export default Hero799;
