import React from "react";
import Hero799 from "../../components/Web799/Hero799/Hero799";
import Features799 from "../../components/Web799/Features/Features799";
import { content799Data } from "../../data/DataWeb799";
import Content799 from "../../components/Web799/Content799/Content799";
import Clients799 from "../../components/Web799/Clients/Clients";
import Showcase799 from "../../components/Web799/Showcases/Showcase";
import Enquiry799 from "../../components/Web799/Equery/Enquiry799";
import { Helmet } from "react-helmet-async";
import UsePageSEO from "../../hook/UsePageSEO";
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import Transition from "../../hook/Transition";

const website799 = () => {
  window.scrollTo(0, 0);

  UsePageSEO({
    title: "Mira Elite | Web $799",
    description: "Web design from $799",
    keywords: ["Web design", "Web 799"],
    ogTitle: "Mira Elite | Web design from $799",
    ogDescription: "Web design price from $799",
    ogSite_name: "Web design $799",
    ogUrl: "https://miraelite.com/web799",
    ogImage: "https://mira-elite.s3.ap-southeast-2.amazonaws.com/bg799.png",
  });

  return (
    <>
      <Helmet>
        <title>Mira Elite | Web $799</title>
        <meta name="description" content="Web design price from $799 " />
        <link rel="canonical" href="/web799" />
      </Helmet>
      <Transition />
      <Hero799 />
      <Features799 />
      <div id="events">
        {content799Data.map((contentData, index) => (
          <Content799 {...contentData} key={index} />
        ))}
      </div>
      <Clients799 />
      <Showcase799 />
      <Enquiry799 />
    </>
  );
};

export default website799;
