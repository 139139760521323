import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Column,
  MainHeading,
  Row,
  TextWrapper,
  TextWrapperHero,
  MainHeading_nail,
  Container,
} from "../../../globalStyles";
import { BiPlayCircle } from "react-icons/bi";
import { HeroSection, ButtonContainer, HeroButton } from "./HeroStyles";

import "./buttonflash.css";
import "./textbg1.css";

const HeroAI = () => {
  return (
    <HeroSection id="hero">
      <Row
        justify="center"
        align="center"
        height="100%"
        padding="0rem"
        minHeight="80vh"
      >
        <Column align="center" mb="0%">
          <div class="text-container">
            <MainHeading_nail>AI Services</MainHeading_nail>
          </div>
          <TextWrapper
            color="white"
            size="clamp(1.2rem,3vw,1.5rem)"
            margin="1rem"
            lineHeight="1.1rem"
            // maxWidth="480px"
            align="center"
            padding="1rem"
          >
            AI Service is a cloud-based service offering artificial intelligence (AI) outsourcing to supercharge local retail trade enterprises
          </TextWrapper>
          {/* <TextWrapper
            color="white"
            mb="1.2rem"
            smSize="1.2rem"
            size="clamp(1.2rem,3vw,1.5rem)"
          >
            Call us:{" "}
            <a
              href="tel: +6435784890"
              style={{ color: "#d4af37", textDecoration: "none" }}
            >
              +64 3578 4890{" "}
            </a>
          </TextWrapper>
          <TextWrapper
            color="white"
            mb="1.2rem"
            smSize="1.2rem"
            size="clamp(1.2rem,3vw,1.5rem)"
          >
            Email us:{" "}
            <a
              href="mailto:diamondnailsblenheim@gmail.com"
              style={{ color: "#d4af37", textDecoration: "none" }}
            >
              diamondnailsblenheim@gmail.com{" "}
            </a>
          </TextWrapper> */}
          <ButtonContainer>
            <ButtonContainer>
              <a
                target="_blank"
                href={"#"}
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                <div className="heroneon">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Quotation
                </div>
              </a>
            </ButtonContainer>
          </ButtonContainer>
        </Column>
      </Row>
    </HeroSection>
  );
};

export default HeroAI;
