import React, { useEffect } from "react";
import { Container } from "../../../styles/globalStyles_event";
import {
  FeatureText,
  FeatureSection,
  FeatureTitle,
  FeatureWrapper,
  FeatureColumn,
  FeatureImageWrapper,
  FeatureName,
  FeatureImage,
  FeatureTextWrapper,
  FeatureMainText,
  FeatureLinks,
} from "./FeaturesStyles";
import { featuresQR } from "../../../data/FeaturesData";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from 'react-router-dom';
import background from "./cement-texture.jpg";

const FeaturesQR = ({ id }) => {
  const initial = {
    scale: 0.2,
    y: 40,
    opacity: 0,
  };

  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1,
        y: 0,
        opacity: 1,
      });
    }
  }, [inView, animation]);

  return (
    <FeatureSection id={id}  style={{ backgroundImage: `url(${background})` }}>
      <Container ref={ref}>
        <FeatureTextWrapper
          initial={initial}
          animate={animation}
          transition={{ duration: 0.3 }}
        >
          <FeatureTitle inverse>{featuresQR.title}</FeatureTitle>
          <FeatureMainText inverse>{featuresQR.text}</FeatureMainText>
        </FeatureTextWrapper>
        <FeatureWrapper>
          {featuresQR.content.map((el, index) => (
            <FeatureColumn
              initial={initial}
              animate={animation}
              transition={{ duration: 0.7 + index * 0.1 }}
              key={index}
            >
              <Link to={el.link}>
              <FeatureImageWrapper>
              

                <FeatureImage src={el.image} alt="feature" />
              </FeatureImageWrapper>
              </Link>
              <FeatureName inverse>{el.name}</FeatureName>
              <FeatureText inverse>{el.description}</FeatureText>
              {/* <FeatureLinks
                spy={true}
                duration={500}
                smooth={true}
                exact="true"
                offset={-80}
                to={el.link}
              >{el.link}</FeatureLinks> */}
            </FeatureColumn>
          ))}
        </FeatureWrapper>
      </Container>
    </FeatureSection>
  );
};

export default FeaturesQR;
