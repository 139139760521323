import React, { useState } from "react";
import { motion } from "framer-motion";
// import { Tooltip } from "@material-tailwind/react";
import './popup.css'

const Modal = ({ open, setOpen }) => {
  const [message, setMessage] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(message);
    setMessage("");
    setOpen(false);
  };
  return (
    <div>
      <motion.div
        className="modalContainer"
        initial={{ scale: 1, opacity: 0, height: 0, width: 0 }}
        animate={{
          x: open ? -30 : 0,
          y: open ? -30 : 0,
          width: open && "300px",
          height: open && "375px",
          opacity: 1,
        }}
        transition={{ type: "spring", duration: 2, ease: "easeInOut" }}
      >
        <motion.div
          className="modalContainerInside"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: "Tween", duration: 2 }}
        >
          <h4 className="modalh5">
          REQUEST A QUOTE
            
          </h4>
          <p className="modalp">
          Hello There 🖐🏻
          </p>
          <span className="modalspan">
          WHAT DO YOU NEED?
          </span>
          <div className="modalform">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="message"
                className="forminside"
                placeholder="Send us a message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></input>
            </form>
          </div>
            <div
              className="modalX"
              onClick={() => setOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#000"
                className="modalSvg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Modal;
