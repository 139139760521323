import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Column,
  MainHeading,
  Row,
  TextWrapper,
  MainHeading_nail,
} from "../../globalStyles";
import { BiPlayCircle } from "react-icons/bi";
import { HeroSection, ButtonContainer, HeroScrollLinks } from "./HeroStyles";

import "./textbg.css";
import "./buttonflash.css";

const Hero = () => {
  return (
    <HeroSection id="hero">
      <Row justify="center" align="center" height="100%" padding="2rem">
        <Column align="center" mb="0%">
        <TextWrapper color="white" size="clamp(1rem,3vw,1.2rem)" margin="0 0 0rem">
            A.I. Web and SEO Agency
          </TextWrapper>
          <div class="text-container1">
            <MainHeading_nail>
              Web Design, SEO , QR Order, AI Services
            </MainHeading_nail>
          </div>
          <TextWrapper
            color="white"
            size="clamp(1rem,3vw,1.3rem)"
            margin="0 0 2rem"
            lineHeight="1.1rem"
            maxWidth="480px"
            align="center"
          >
            Explore our highly efficient AI | Web development tools solutions tailored
            for your businesses.
          </TextWrapper>
          <ButtonContainer>
            <HeroScrollLinks
              spy={true}
              duration={500}
              smooth={true}
              exact="true"
              offset={-60}
              to={"ourservice"}
            >
              <a
                target="_blank"
                href={"#"}
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                <div className="heroneon">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Our Services
                </div>
              </a>
            </HeroScrollLinks>
            {/* <Button big fontBig>
							<BiPlayCircle size="2.3rem" /> Download
						</Button> */}
          </ButtonContainer>
        </Column>
      </Row>
    </HeroSection>
  );
};

export default Hero;
