import React, { useState } from "react";
import { Container } from "../../../styles/globalStyles799";
import {
  ClientSection,
  ClientColumn,
  ClientText,
  ClientTitle,
  ClientRow,
  ClientWrapper,
  ClientImage,
  ClientTextWrapper,
  ClientsGrid,
  ClientImageModal,
} from "./ClientsStyles";
import { clientsData, clientsData799 } from "../../../data/ClientsData";
import Modal799 from "../Modal799/Modal799";

const Clients799 = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);

  const handleClick = (index) => {
    setShowModal(true);
    setSelectedImage(index);
  };

  return (
    <>
      <ClientSection id="clients">
        <Container>
          <ClientTextWrapper>
            <ClientTitle>Selected Clients</ClientTitle>
            <ClientText>
              We’ve been working with Clients around New Zealand. Click to view.
            </ClientText>
          </ClientTextWrapper>

          <ClientsGrid>
            {clientsData799.map((clients, clientsIndex) => (
              <ClientWrapper
                key={clientsIndex}
                onClick={() => {
                  handleClick(clientsIndex);
                }}
              >
                <ClientImage src={clients.image} />
              </ClientWrapper>
            ))}
          </ClientsGrid>

          {/* <ClientRow>
            {clientsData.map((clients, clientsIndex) => (
              <ClientColumn key={clientsIndex}>
                {clients.map((el, index) => (
                  <ClientWrapper
                    key={index}
                    onClick={() => {
					  handleClick(index);
					  
                    }}
                  >
                    <ClientImage src={`/web799/${el.name}.png`} />
                  </ClientWrapper>
                ))}
              </ClientColumn>
            ))}
          </ClientRow> */}
        </Container>
      </ClientSection>
      <Modal799 show={showModal} onHide={() => setShowModal(false)}>
        <ClientImageModal src={clientsData799[selectedImage].image} />

        <ClientTitle>{clientsData799[selectedImage].name}</ClientTitle>
        <ClientText maxWidth="500px">
          {clientsData799[selectedImage].desc}
        </ClientText>
        <a
          target="_blank"
          href={clientsData799[selectedImage].link}
          style={{
            textDecoration: "none",
            color: "#fff",
          }}
        >
          <div className="heroneon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Visit Website
          </div>
        </a>
      </Modal799>
    </>
  );
};

export default Clients799;
