import React from 'react';
import ReactDOM from 'react-dom';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import { hydrate, render } from 'react-dom';


import App from './App';




const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<HelmetProvider><App /></HelmetProvider>, rootElement);
} else {
  render(<HelmetProvider><App /></HelmetProvider>, rootElement);
}

// ReactDOM.render(  <React.StrictMode>
//     <HelmetProvider>
//       <App />
//     </HelmetProvider>
//   </React.StrictMode>, document.getElementById('root'));
