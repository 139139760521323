import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import "./transition.css";
import { opacity, expand } from "./Anim";
import Curve from "./curve/curve";

const Transition = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  const nbOfColumns = 5;

  const anim = (variants, custom = null, exit) => {
    return {
      initial: "initial",
      animate: "enter",
      exit,
      custom,
      variants,
    };
  };

  function getRandomInt(min, max) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
  }

  const random = getRandomInt(1, 4);

  // const random = 3;

  // console.log({ random });

  const Motion1 = () => {
    return (
      <div style={{ backgroundColor: "#0f0f0f0", display: "flex" }}>
        <motion.div
          className="slide-in"
          initial={{ scaleX: 1 }}
          animate={{ scaleYeX: 0 }}
          exit={{ scaleX: 0 }}
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        />
        <motion.div
          className="slide-out"
          initial={{ scaleX: 1 }}
          animate={{ scaleX: 0 }}
          exit={{ scaleX: 0 }}
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        />
      </div>
    );
  };

  const Motion2 = () => {
    return (
      <div className="stairs">
        <motion.div {...anim(opacity)} className="transition-background" />
        <div className="transition-container">
          {[...Array(nbOfColumns)].map((_, i) => {
            return <motion.div key={i} {...anim(expand, nbOfColumns - i)} />;
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <motion.div initial="hidden" animate="show">
        <AnimatePresence>
          {loaded ? null : (
            <>
              {random === 3 && <Motion1 />}
              {random === 2 && <Motion2 />}
              {random === 1 && <Curve backgroundColor={"#ff0000"} />}
            </>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
};

export default Transition;
