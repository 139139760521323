import styled from "styled-components";
import { Row, Section } from "../../globalStyles";
import { orange } from "../../Colors";
import { Link as LinkScroll } from "react-scroll";

export const HeroSection = styled(Section)`
  
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.8)),;
  -webkit-animation: slidein 20s;
  animation: slidein 20s;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -webkit-animation-direction: alternate;
  animation-direction: alternate;

  padding-top: 200px;
  height: 10vh;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  display: flex;

  @media screen and (max-width: 1200px) {
    // padding-top: 00px;
    height: 5vh;
  }

  @media screen and (max-width: 768px) {
    padding-top: 00px;
    height: 5vh;
  }

  @-webkit-keyframes slidein {
    from {
      background-position: top;
      background-size: 2000px;
    }
    to {
      background-position: -200px 100px;
      background-size: 1750px;
    }
  }

  @keyframes slidein {
    from {
      background-position: top;
      background-size: 2000px;
    }
    to {
      background-position: -800px 0px;
      background-size: 1750px;
    }
  }
`;

export const ButtonContainer = styled(Row)`
  justify-content: center;
  flex-flow: wrap;

  button {
    background-color: transparent;
    margin: 0 0.3rem;
  }
  button:nth-child(1) {
    border: 2px solid ${orange};

    &:hover {
      border: 2px solid transparent;
    }
  }

  button:nth-child(2) {
    padding: 8px 32px;

    display: flex;
    align-items: center;

    &:hover {
      background-color: transparent;
      color: ${orange};
    }
    > svg {
      margin-right: 0.4rem;
    }
  }
`;

export const HeroScrollLinks = styled(LinkScroll)`
  text-decoration: none;

  &.active {
    border-bottom: 2px solid #ebc214;
  }
  &:hover {
    border-bottom: 2px solid #ff0000;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 0rem;
    // width: 100%;
    display: table;

    &:hover {
      color: #4b59f7;
      transition: all 0.3s ease;
    }
  }
`;
