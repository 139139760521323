export const seoOne = {
	id: 'about',
	inverse: true,
	bigImage: true,
	topLine: {
		text: 'Firstly, lets rescue that website',
		extraText: '$799',
	},
	headline: 'Most incorrectly assume a large initial investment is needed.',
	description:
		'When you see the before and after case studies on our home page, it’s easy to assume that there must have been a substanial investment to onboard a new website, run our checklists for website health, redesign the website so it’s looking good and position the website to attract dream customers. In fact, the rescue process is covered by our website care plan.',
	buttonLabel: 'Sign Up Now',
	imgStart: 'start',
	img: 'https://images.pexels.com/photos/267415/pexels-photo-267415.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
	bottomImg: 'https://images.pexels.com/photos/267401/pexels-photo-267401.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
	start: 'true',
};

export const seoTwo = {
	id: 'services',

	reverse: true,

	lightBg: false,
	topLine: {
		text: 'Best collection',
		extraText: 'Secondly…',
	},
	headline: 'Many are concerned about high ongoing costs for maintanence and support.',
	description:
		`To ensure your website continues to operate at its best, there are daily, weekly, monthly and annual maintenance requirements – hosting, SSL certification, spam, security, CMS, plugin updates as we’ll as general support for when something inevitably breaks – which they always do.

        `,
	buttonLabel: 'Learn More',
	linkTo: '/',
	imgStart: '',
	img: 'https://images.pexels.com/photos/185576/pexels-photo-185576.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
	bottomImg: 'https://images.pexels.com/photos/3943739/pexels-photo-3943739.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
	alt: 'Vault',
	start: 'true',
};

export const seoThree = {
	id: 'products',

	bigImage: true,
	smallSection: true,
	inverse: true,
	topLine: {
		text: 'Thirdly…',
		extraText: '',
	},
	headline: 'No more need to fear engaging your developer and big invoices.',
	description:
		`We know small and medium business have a lot to consider when it comes to cash flow, and quite often the proactive enhancement of their website, keeping it up to date, refreshed and relevant is low on the list, especially where engagement with most traditional web development companies comes with a big invoice.`,
	buttonLabel: 'Download',
	linkTo: '/download',
	imgStart: '',
	img: 'https://images.pexels.com/photos/3197390/pexels-photo-3197390.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
	bottomImg: 'https://images.pexels.com/photos/669612/pexels-photo-669612.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
	alt: 'Vault',
	start: 'true',
};

export const seoFour = {
	id: 'services',

	reverse: true,

	lightBg: false,
	topLine: {
		text: 'And finally…',
		extraText: '',
	},
	headline: 'We will build you a new website every 36 months… for only $799!',
	description:
		`With web technology moving so quickly, and businesses themselves evolving over time, we feel every three years or so is a good timeframe to rebuild your website.

        `,
	buttonLabel: 'Book Now',
	linkTo: '/',
	imgStart: '',
	img: 'https://images.pexels.com/photos/6476808/pexels-photo-6476808.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
	bottomImg: '',
	alt: 'Vault',
	start: 'true',
};