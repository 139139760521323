import React from 'react';

import { GiPartyFlags, GiMicrophone } from 'react-icons/gi';
import { TiGroupOutline } from 'react-icons/ti';
import { SiEsotericsoftware } from "react-icons/si";

import { BsPhone } from 'react-icons/bs';
import { GiConcentrationOrb } from 'react-icons/gi';
import { IoLogoXing } from 'react-icons/io';
import { HiCode } from 'react-icons/hi';
import { MdSlowMotionVideo } from 'react-icons/md';
import { FaPhotoVideo } from 'react-icons/fa';
import { FaReact } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";

import { FaRocketchat } from "react-icons/fa";
import { FaBattleNet } from "react-icons/fa";

import { BiAperture } from "react-icons/bi";
import { FaBraille } from "react-icons/fa";


const iconStyle = (Icon) => <Icon size="3rem" color="#fefefe" />;

export const featuresData = {
	title: 'Our Services',
	text: "Click to view",
	content: [
		{
			name: 'Website and SEO',
			description: 'We specialize in website design and offer comprehensive SEO and web care plans to enhance the performance of clients websites',
			icon: iconStyle(TiGroupOutline),
			image: '/features/seo.svg',
			link: '/web799'
		},
		{
			name: 'QR Software',
			description: 'We offer a range of online software solutions including a QR scan and order system, digital loyalty card, and booking .',
			icon: iconStyle(GiMicrophone),
			image: '/features/software.svg',
			link: '/qr-order'
		},
		{
			name: 'AI services',
			description: 'We offer AI services aimed at enhancing the intelligence of our clients websites and facilitating interactions with their customers.',
			icon: iconStyle(GiPartyFlags),
			image: '/features/ai.svg',
			link: '/ai-service'
		},
		{
			name: 'Research',
			description: 'We conduct in-depth analysis of large datasets in order to establish a strategic framework.',
			icon: iconStyle(GiPartyFlags),
			image: '/features/survey.svg',
			link: '/research'
		},
	],
};

export const featuresData799 = {
	title: 'Advantages',
	text: "Click to view the details.",
	content: [
		{
			id: 'react',
			name: 'React',
			description: 'We design modern coding language React',
			icon: iconStyle(FaReact),
			imgClass: 'one',
			image: '/features/seo.svg',
			link: '/website-seo'
		},
		{
			id: 'framermotion',
			name: 'Framer Motion',
			description: 'We create animation websites using Framer Motion.',
			icon: iconStyle(FaBattleNet),
			imgClass: 'two',
			image: '/features/seo.svg',
			link: '/website-seo'
		},
		{
			id: 'uxui',
			name: 'UI/UX',
			description: 'We utilize modern UX/UI design components such as Styled Components, Material-UI, Antd, Tailwind CSS, and Bootstrap.',
			icon: iconStyle(FaBraille),
			imgClass: 'three',
			image: '/features/ai.svg',
			link: '/ai-service'
		},
		{
			id: 'instagram',
			name: 'Instagram',
			description: 'We integrate with Instagram data.',
			icon: iconStyle(FaInstagram),
			imgClass: 'four',
			image: '/features/software.svg',
			link: '/qr-order'
		},

		{
			id: 'facebook',
			name: 'FaceBook/ Facebook Chat Plugin',
			description: 'We integrate with Facebook Fanpage and Facebook Chat Plugin',
			icon: iconStyle(FaFacebook),
			imgClass: 'five',
			image: '/features/survey.svg',
			link: '/research'
		},
		{
			id: 'google',
			name: 'Google',
			description: 'We integrate with Google review data.',
			icon: iconStyle(FaGoogle),
			imgClass: 'six',
			image: '/features/survey.svg',
			link: '/research'
		},
	],
};

export const featuresQR = {
	title: 'Our Advantages',
	text: "Our software provides a range of services to enhance your business operations. With our QR scan to order, booking, and digital loyalty card features, we offer a comprehensive solution to improve customer experience and streamline your business processes.",
	content: [
		{
			name: 'QR scan to Order',
			description: 'Provide a QR code for customers to scan with their mobile devices, allowing them to access our menu and place orders directly.',
			icon: iconStyle(TiGroupOutline),
			image: '/QRpage/qr-scan.svg',
			link: '/website-seo'
		},
		{
			name: 'Digital Loyalty Card',
			description: 'Our digital loyalty card service allows business owners and customers to easily manage loyalty programs with thousands of digital cards stored in the cloud.',
			icon: iconStyle(GiMicrophone),
			image: '/QRpage/card.svg',
			link: '/qr-order'
		},
		{
			name: 'Online Booking',
			description: 'Customers can easily book happy hour slots to avail discounts, while business owners can increase their revenue during slow hours.',
			icon: iconStyle(GiPartyFlags),
			image: '/QRpage/hour.svg',
			link: '/ai-service'
		},
	],
};


export const featuresAI = {
	title: 'Main AI services',
	text: "We offer cloud-based applications that integrate the MERN stack with AI capabilities to enhance websites and bring greater intelligence to them. Our approach involves utilizing an Interest Scoring Model to analyze and convert people's attributes into numerical values, enabling us to identify products that align with their interests based on appropriate interest scores.",
	content: [
		{
			name: 'Design AI brain',
			description: 'Provide a QR code for customers to scan with their mobile devices, allowing them to access our menu and place orders directly.',
			icon: iconStyle(TiGroupOutline),
			image: '/ai-page/ai-brain.svg',
			link: '/website-seo'
		},
		{
			name: 'MERN stack plus AI service',
			description: 'We utilize an advanced RAMBO full stack technology, which combines the MERN stack with AI design, to develop our AI website.',
			icon: iconStyle(GiMicrophone),
			image: '/ai-page/webcode.svg',
			link: '/qr-order'
		},
	],
};

export const featuresR = {
	title: 'Research Services',
	text: "We provide a full range of survey research services, including online surveys, postal surveys, and the use of secondary data from sources such as Bloomberg, World Bank, and other public sources.",
	content: [
		{
			name: 'Research team',
			description: 'Our team of researchers includes PhD holders from many high-ranking universities.',
			icon: iconStyle(TiGroupOutline),
			image: '/research/phd2.svg',
			link: '/qr-order'
		},
		{
			name: 'Combined Statistic and Artificial Intelligent Models',
			description: 'We leverage a combination of advanced statistical models and artificial intelligence models to uncover valuable insights from the vast amount of data available to us.',
			icon: iconStyle(GiMicrophone),
			image: '/research/research.svg',
			link: '/qr-order'
		},
	],
};