import React, { useEffect, useState } from 'react';
import { Container, Container1, Section, Section1 } from '../../../styles/globalStyles_event';
import { MasonryGrid, EventImageWrapper, Image, EventHeading } from './EventsSectionStyles';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import Modal from '../Modal/Modal';
import { ContentButton, ContentHeading, Img, ImgWrapper, Subtitle } from '../Content/ContentStyles';
import { eventsData } from '../../../data/EventsData';
import { Link } from 'react-router-dom';

const EventsSection = () => {
	const animation = useAnimation();
	const [showModal, setShowModal] = useState(false);
	const [selectedImage, setSelectedImage] = useState(0);
	const [selectedTitle, setSelectedTitle] = useState(0);
	const [selectedDecs, setSelectedDecs] = useState(0);
	const [selectedLink, setSelectedLink] = useState(0);
	const { ref, inView } = useInView({
		threshold: 0.2,
	});

	useEffect(() => {
		if (inView) {
			animation.start({
				opacity: 1,
				scale: 1,
			});
		}
	}, [inView, animation]);

	const handleClick = (index) => {
		setShowModal(true);
		setSelectedImage(index);
		setSelectedTitle(index);
		setSelectedDecs(index);
		setSelectedLink(index);
	};

	return (
		<>
			<Section1>
				<Container1>
					<EventHeading
						initial={{ opacity: 0, scale: 0.3 }}
						transition={{ duration: 0.4 }}
						animate={animation}
					>
						Our projects
					</EventHeading>
					<MasonryGrid ref={ref}>
						{eventsData.map((item, index) => (
							<EventImageWrapper
								animate={animation}
								initial={{
									opacity: 0,
									scale: 0.3,
								}}
								transition={{ duration: 0.8 }}
								className={item.class}
								key={index}
								onClick={() => handleClick(index)}
							>
								<Image src={item.img}></Image>
							</EventImageWrapper>
						))}
					</MasonryGrid>
				</Container1>
			</Section1>
			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<ContentHeading inverse>{eventsData[selectedTitle].title}</ContentHeading>
				<ImgWrapper>
					<Img src={eventsData[selectedImage].img} alt="image" />
				</ImgWrapper>
				<Subtitle mt inverse>
				{eventsData[selectedDecs].decs}
				</Subtitle>
				<a href={eventsData[selectedLink].link} target='_blank'>
				<ContentButton inverse >Visite website</ContentButton></a>
			</Modal>
		</>
	);
};

export default EventsSection;
