import React from "react";
import { Container } from "../../../styles/globalStyles799";
import {
  FeatureText,
  FeatureSection,
  FeatureTitle,
  FeatureWrapper,
  FeatureColumn,
  FeatureImageWrapper,
  FeatureName,
  FeatureTextWrapper,
  FeatureText2,
  FeatureLinks,
} from "./FeaturesStyles";

import { featuresData799 } from "../../../data/FeaturesData";

const Features799 = () => {
  return (
    <FeatureSection id="about">
      <Container>
        <FeatureTextWrapper>
          <FeatureTitle>{featuresData799.title}</FeatureTitle>
          <FeatureText2>{featuresData799.text}</FeatureText2>
        </FeatureTextWrapper>
        <FeatureWrapper>
          {featuresData799.content.map((el, index) => (
            <FeatureColumn key={index}>
              <FeatureImageWrapper className={el.imgClass}>
                {el.icon}
              </FeatureImageWrapper>
              <FeatureLinks
                spy={true}
                duration={500}
                smooth={true}
                exact="true"
                offset={50}
                to={el.id}
              >
                <FeatureName>{el.name}</FeatureName>
              </FeatureLinks>

              <FeatureText>{el.description}</FeatureText>
            </FeatureColumn>
          ))}
        </FeatureWrapper>
      </Container>
    </FeatureSection>
  );
};

export default Features799;
