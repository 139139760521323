export const eventsData = [
	{	
		title: 'Caddyshack city',
		img: './assets/caddyshack.png',
		decs:`The most excited indoor mini golf. Located in our indoor, all-weather venue just a few minutes drive from Christchurch’s centre . Each of our 18 holes incorporates a different theme with some amazing scale models, and each offers different skill challenges and fun.`,
		link:'https://caddyshack.co.nz/'
	},
	{
		title: 'Diamonad Nails Blemheim',
		img: './assets/diamond.png',
		class: 'tall',
		decs:`Here you will be greeted by our team of experts who are here to provide you with a professional experience`,
		link:'https://diamondnailsblenheim.com/'
	},

	{
		title: 'Taupo Luxury Nails',
		img: './assets/taupo.png',
		decs:`STEP INTO A WORLD OF NAIL ELEGANCE.
		Taupo Luxury Nails Is Proud To Be One Of The Best Nail Salons, Located Conveniently In Taupo. Our Nail Salon Will Create An Ideal Space For You To Enjoy The Most Comfortable Moments.`,
		link:'https://www.taupoluxurynails.co.nz/'
	},
	{
		title: 'Tower Nails & Beauty',
		img: './assets/tower.png',
		decs:`This is one of the biggest and well-equipped nail salons in Christchurch with updated techniques & quality products. We offer nail treatments, eyelashes, tinting, and waxing at the most affordable price`,
		class: 'wide',
		link:'https://towerbeauty.co.nz/'
	},
	{
		title: 'Regal Nails',
		img: './assets/regal.png',
		decs:`You Love It. We'll Nail It ♡︎. All About Nails & Lashes ✿.`,
		class: 'big',
		link:'https://regalnail.co.nz/'
	},
	{
		title: 'Shop Co Tu',
		img: './assets/shopcotu.png',
		decs:`This is a commerce website (type 1)`,
		link:'https://shopcotu.com/'
	},
	{
		title: 'Vietemart',
		img: './assets/vietemart.png',
		class: 'tall',
		decs:`This is a commerce website (type 2)`,
		link:'https://vietemart.com/'
	},
	{
		title: 'Global Mira',
		img: './assets/globalmira.png',
		class: 'tall',
		decs:`This is a 3D website`,
		link:'https://globalmira.com/'
	},
	{	title: 'LOKALAI (AI website)',
		img: './assets/lokalai.png',
		decs:`This is a AI website controlled by Artificial Inteligence`,
		link:'https://globalmira.com/'
	},
	// {
	// 	img: './assets/masonry8.jpg',
	// },
	// {
	// 	img: './assets/masonry9.jpg',
	// },
];

export const eventsDataQR = [
	{	
		title: 'ROYAL VIETNAMESE CAFÉ & RESTAURANT',
		img: './QRPage/royal.jpg',
		imgQR: './QRPage/royalQR.png',
		decs:`You can easily scan the QR code using your mobile device or simply click the "Visit Menu" button.`,
		link:'https://lokalai.com/operator/menu/65d2ad5c8facf6bfe9023a05'
	},
	{
		title: 'HOUSE CANTEEN',
		img: './QRPage/HouseCanteen.png',
		imgQR: './QRPage/houseQR.png',
		decs:`You can easily scan the QR code using your mobile device or simply click the "Visit Menu" button.`,
		link:'https://lokalai.com/operator/menu/65d2ad5c8facf6bfe9023a06'
	},
	{
		title: 'LITTLE VIETNAM CHRISTCHURCH',
		img: './QRPage/littlelogo.jpg',
		imgQR: './QRPage/littleQR.png',
		decs:`You can easily scan the QR code using your mobile device or simply click the "Visit Menu" button.`,
		class: 'big',
		link:'https://lokalai.com/operator/menu/65d6b639bb1369f6006dd3ef'
	},

	{
		title: 'BB VIETNAMESE STREET FOOD',
		img: './QRPage/logoBeBau.jpg',
		imgQR: './QRPage/BBQR.png',
		decs:`You can easily scan the QR code using your mobile device or simply click the "Visit Menu" button.`,
		link:'https://lokalai.com/operator/menu/65d6b639bb1369f6006dd3ee'
	},

	{
		title: 'MIDDEN',
		img: './QRPage/ddlogo.png',
		imgQR: './QRPage/ddQR.png',
		decs:`You can easily scan the QR code using your mobile device or simply click the "Visit Menu" button.`,
		// class: 'big',
		link:'https://lokalai.com/operator/menu/65d2ad5c8facf6bfe9023a07'
	},
	

];