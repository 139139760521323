import React from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin } from 'react-icons/fa';

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	{
		name: 'Facebook',
		icon: iconStyle(FaFacebook),
	},
	{
		name: 'Instagram',
		icon: iconStyle(FaInstagram),
	},
	{
		name: 'YouTube',
		icon: iconStyle(FaYoutube),
	},
	{
		name: 'Twitter',
		icon: iconStyle(FaTwitter),
	},
	{
		name: 'LinkedIn',
		icon: iconStyle(FaLinkedin),
	},
];

export const footerData = [
	{
		title: 'Website $799',
		image: '/features/web799.svg',
		link: '/web799',
		links: ['FAQs', 'Support', 'About us'],
	},
	{
		title: 'SEO & Marketing',
		image: '/features/seo.svg',
		link: '/website-seo',
		links: ['Website $799', 'Business', 'Team'],
	},
	{
		title: 'QR Menu Order',
		links: ['Events', 'Stories', 'Office'],
		image: '/features/software.svg',
		link: '/qr-order',
	},
	{
		title: 'A.I. Services',
		links: ['Events', 'Stories', 'Office'],
		image: '/features/ai.svg',
		link: '/ai-service'
	},
];
