import React from 'react'
import HeroQR from '../../components/QR Order Page/HeroQR/HeroQR'
import FeaturesQR from '../../components/QR Order Page/FeaturesQR/FeaturesQR'
import { QRData } from '../../data/ContentData'
import ContentQR from '../../components/QR Order Page/ContentQR/ContentQR.js'
import EventsQR from '../../components/QR Order Page/EventsQR/EventsQR.js'
import EnquiryQR from '../../components/QR Order Page/EnquiryQR/EnquiryQR.js'
import { Helmet } from 'react-helmet-async'
import UsePageSEO from '../../hook/UsePageSEO.js'
import Transition from '../../hook/Transition.js'

const qrpage = () => {
  window.scrollTo(0, 0)

 UsePageSEO({
    title: "Mira Elite | QR order, digital loyalty cards, booking",
    description:'Online software : QR order, digital loyalty cards, booking',
    keywords:["QR order system","digital loyalty cards", "booking","software"],
    ogTitle:"Mira Elite | QR order, digital loyalty cards, booking",
    ogDescription:" Online software : QR order, digital loyalty cards, booking",
    ogSite_name:"QR order, digital loyalty cards, booking",
    ogUrl:"https://miraelite.com/qr-order",
    ogImage:"https://mira-elite.s3.ap-southeast-2.amazonaws.com/qr.jpg"
  })
  return (
    <>
      <Helmet>
        <title>Mira Elite | QR code software</title>
        <meta name="description" content="Software : QR Scan Order, Digital loyalty card, Booking software" />
        <link rel="canonical" href="/qr-order" />
       
      </Helmet>
      <Transition />
    <HeroQR />
    <FeaturesQR />
    <div id="events">
				{QRData.map((contentData, index) => (
					<ContentQR {...contentData} key={index} />
				))}
			</div>
      <EventsQR />
      <EnquiryQR />
    </>)
    
}

export default qrpage