import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import DrawSvg from "./DrawSvg";
import { Link } from "react-router-dom";

const Section = styled.section`
  min-height: 90vh;
  width: 90%;
  background-color: ${(props) => props.theme.body};

  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
    width: 100%;
  }
`;
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;

  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.fontxl};
    justify-content: start;
    margin-bottom: 1rem;
  }
`;
const Container = styled.div`
  width: 50%;
  height: 80vh;
  background-color: transparent;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
  }
`;
const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Items = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  @media (max-width: 768px) {
    width: 85%;
    background: transparent;
  }

  & > *:nth-of-type(2n + 1) {
    justify-content: start;
    @media (max-width: 768px) {
      justify-content: center;
      margin-left: 4rem;
    }

    div {
      border-radius: 50px 0 50px 0;
      text-align: right;

      @media (max-width: 768px) {
        border-radius: 0 50px 0 50px;
        text-align: left;
        p {
          border-radius: 0 40px 0 40px;
        }
      }
    }
    p {
      border-radius: 40px 0 40px 0;
    }
  }
  & > *:nth-of-type(2n) {
    justify-content: end;
    @media (max-width: 768px) {
      justify-content: center;
      margin-left: 4rem;
    }
    div {
      border-radius: 0 50px 0 50px;
      text-align: left;
    }
    p {
      border-radius: 0 40px 0 40px;
    }
  }
`;
const Item = styled.li`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 48em) {
    justify-content: flex-start !important;
  }
`;
const ItemContainer = styled.div`
  width: 47%;
  height: fit-content;
  padding: 1rem;
  border: 3px solid #fff;
  background-color: #fff;
  min-height: 120px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
  }
`;

const Box = styled.p`
  height: fit-content;
  background-color: transparent;
  color: #ff0000;
  padding: 1rem;
  position: relative;
  border: 1px solid #ff0000;
  min-height: 80px;
`;

const SubTitle = styled.h1`
  display: block;
  font-size: 1.3rem;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #ff0000;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontlg};
    font-weight: 600;
  }
`;
const Text = styled.span`
  display: block;
  font-size: 1.2rem;
  text-transform: capitalize;
  color: #000;

  font-weight: 400;
  margin: 0.5rem 0;
  @media (max-width: 40em) {
    font-size: 1rem;
  }
`;

const RoadMapItem = ({ title, subtext, addToRef, link }) => {
  return (
    <Item ref={addToRef}>      
        <ItemContainer> 
          <Box>
            <SubTitle>{title} </SubTitle>
            <Text>{subtext}</Text>
          </Box>
        </ItemContainer>
    </Item>
  );
};

const MiraRoadmap = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];
  gsap.registerPlugin(ScrollTrigger);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  useLayoutEffect(() => {
    let t1 = gsap.timeline();
    revealRefs.current.forEach((el, index) => {
      t1.fromTo(
        el.childNodes[0],
        {
          y: "0",
        },
        {
          y: "-30%",

          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top center+=200px",
            end: "bottom center",
            scrub: true,
            // markers:true,
          },
        }
      );
    });

    return () => {
      if (t1) t1.kill();
    };
  }, []);

  return (
    <Section id="roadmap">
      <Title>Roadmap</Title>
      <Container>
        <SvgContainer>
          <DrawSvg />
        </SvgContainer>
        <Items>
          <Item>&nbsp;</Item>
          <RoadMapItem
            addToRef={addToRefs}
            title="Website Design"
            subtext="Web coding | UX/UI | Motion"
            link="/web799"
          />

          <RoadMapItem
            addToRef={addToRefs}
            title="Grow Website"
            subtext="SEO | Social Media | Google Analytics"
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="QR online software"
            subtext="QR scan order system | Digital Loyalty Card | Booking"
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="AI services"
            subtext="Design AI brains | Connect and make 'intelligent' website | Rambo full stack"
          />
          <RoadMapItem
            addToRef={addToRefs}
            title="Research"
            subtext="Investigate big data to develop new strategies and theories."
          />
        </Items>
      </Container>
    </Section>
  );
};

export default MiraRoadmap;
