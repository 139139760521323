import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaArrowUp } from "react-icons/fa";
import "./toptip.css";

const BacktoTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
    let heightToHidden = 20;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <Wrapper>
      {isVisible && (
          <div className="top-btn" onClick={goToBtn}>
            <svg
              fill="#fff"
              version="1.1"
              id="icon"
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 32 32"
            >
              <polygon
                points="16,14 6,24 7.4,25.4 16,16.8 24.6,25.4 26,24 "
                stroke="#fff"
                stroke-width="1"
              />
              <rect
                x="4"
                y="8"
                width="24"
                height="2"
                stroke="#ff0000"
                stroke-width="1"
              />
              <rect
                id="_Transparent_Rectangle_"
                fill="none"
                width="32"
                height="32"
              />
            </svg>
          </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  :root {
    --glow-color: #fff;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .top-btn {
    font-size: 2rem;
    width: 3.5rem;
    height: 3.5rem;
    color: #fff;
    background-color: transparent;
    box-shadow: #ff0000;
    border-radius: 50%;
    position: fixed;

    
    bottom: 13rem;
    right: 8.5rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0.1em solid var(--glow-color);

    -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
      0px 0px 0.5em 0px var(--glow-color);
    animation: border-flicker 2s linear infinite;

    &--icon {
      animation: gototop 1s linear infinite alternate-reverse;
      -webkit-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
        0 0 0.45em var(--glow-color);
      -moz-text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
        0 0 0.45em var(--glow-color);
      text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3),
        0 0 0.45em var(--glow-color);
      animation: text-flicker 3s linear infinite;
    }

    .top-btn::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.7;
      filter: blur(1em);
      transform: translateY(120%) rotateX(95deg) scale(1, 0.35);
      background: var(--glow-color);
      pointer-events: none;
    }

    .top-btn::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: -1;
      background-color: var(--glow-color);
      box-shadow: 0 0 2em 0.2em var(--glow-color);
      transition: opacity 100ms linear;
    }

    .top-btn:hover {
      color: rgba(255, 255, 255, 0.8);
      text-shadow: none;
      animation: none;
    }

    @keyframes gototop {
      0% {
        transform: translateY(-0.25rem);
      }
      100% {
        transform: translateY(0.25rem);
      }
    }
  }

  @media (max-width: 640px) {
    .top-btn {
      right: 1.1rem;
      bottom: 11rem;
      color: #ff0000;
      font-size: 1.5rem;
      border: 1px solid #fff;
      width: 3rem;
      height: 3rem;
    }
  }

  @keyframes border-flicker {
    0% {
      opacity: 0.1;
    }
    2% {
      opacity: 1;
    }
    4% {
      opacity: 0.1;
    }

    8% {
      opacity: 1;
    }
    70% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes text-flicker {
    0% {
      opacity: 0.1;
    }

    2% {
      opacity: 1;
    }

    8% {
      opacity: 0.1;
    }

    9% {
      opacity: 1;
    }

    12% {
      opacity: 0.1;
    }
    20% {
      opacity: 1;
    }
    25% {
      opacity: 0.3;
    }
    30% {
      opacity: 1;
    }

    70% {
      opacity: 0.7;
    }
    72% {
      opacity: 0.2;
    }

    77% {
      opacity: 0.9;
    }
    100% {
      opacity: 0.9;
    }
  }
`;

export default BacktoTop;
