
const content799One = {
	id: 'react',
	topLine: {
		text: 'Modern Coding Language.',
	},
	headline: 'React',
	description: 'We use React for design frontend ',
	longDesc:``,
	buttonLabel: 'View More',
	imgStart: 'start',
	img: '/web799/react2.svg',
	backgroundColor: 'linear-gradient(130deg, #b70b0d 0%, #ffb401 100%)',
	start: 'true',
};

const content799Two = {
	id: 'framermotion',
	reverse: true,
	topLine: {
		text: 'Create Amination Website',
	},
	headline: 'We use Framer Motion | GSAP to create amination web.',
	description:
		"Our team worked for various clients, I don't know what else to write here, it's not my job!",
	buttonLabel: 'View Project',

	linkTo: '/',
	imgStart: 'start',
	img: '/web799/framer.svg',
	backgroundColor: 'linear-gradient(220deg, #ffb401 0%, #C2240b 100%)',

	start: 'true',
};

export const content799Three = {
	id: 'uxui',

	topLine: {
		text: 'User friendly experinces',
	},
	headline: "We use UX/UI to create friendly website for users",
	description:
		'Our team has amazing developers that can turn any design sketches and ideas into a fully functioning product.',
	buttonLabel: 'View More',

	linkTo: '/download',
	imgStart: '',
	backgroundColor: 'linear-gradient(130deg, #ffb401 0%, #C2240b 100%)',
	img: '/web799/ux.svg',
	start: 'true',
};

export const content799Four = {
	id: 'instagram',
	reverse: true,
	topLine: {
		text: 'Social Media',
	},
	headline: 'We intergrate data from Instagram',
	description: 'We take your products and make them look better than they already are!',
	buttonLabel: 'View More',

	linkTo: '/',
	imgStart: '',
	backgroundColor: 'linear-gradient(130deg, #ff0000 0%, #ffb401 100%)',
	img: '/web799/ins.svg',
	start: 'true',
};

export const content799Five = {
	id: 'facebook',

	topLine: {
		text: 'Social Media',
	},
	headline: "We embed Facebook fan page plus Facebook Chat Plugin to your website",
	description:
		'Our team has amazing developers that can turn any design sketches and ideas into a fully functioning product.',
	buttonLabel: 'View More',

	linkTo: '/download',
	imgStart: '',
	backgroundColor: 'linear-gradient(130deg, #ffb401 0%, #b70b0d 100%)',
	img: '/web799/fb.svg',
	start: 'true',
};

export const content799Six = {
	id: 'google',
	reverse: true,
	topLine: {
		text: 'Google Review',
	},
	headline: 'Google Review',
	description: 'We integrate data from Google Review to connect your website. We also clasify low stars and high stars review',
	buttonLabel: 'View Project',

	linkTo: '/',
	imgStart: '',
	backgroundColor: 'linear-gradient(220deg, #ffb401 0%, #b70b0d 100%)',
	img: '/web799/gg.svg',
	start: 'true',
};


export const content799Data = [content799One, content799Two, content799Three, content799Four, content799Five, content799Six];
