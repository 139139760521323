import React, { useEffect, useState } from "react";
import { Container } from "../../../styles/globalStyles_event";
import { Button } from "../../../globalStyles";
import { IoIosCall } from "react-icons/io";
import {
  ContentSec,
  ContentRow,
  TextWrapper,
  TopLine,
  ContentHeading,
  ContentButton,
  Subtitle,
  ImgWrapper,
  Img,
  ContentColumn,
  Box,
  SubtitleTop,
  ContentHeadingModal,
  SubtitleModal,
  ContentButtonModal,
  ButtonContainer,
} from "./ContentStyles";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import ModalAI from "../ModalAI/ModalAI";
import { Link } from "react-router-dom";
import { BiBrain } from "react-icons/bi";

const ContentAI = ({
  primary,
  topLine,
  headline,
  description,
  longDesc,
  buttonLabel,
  img,
  alt,
  inverse,
  reverse,
  bigImage,
  bottomImg,
  linkMako,
}) => {
  const [showModal, setShowModal] = useState(false);
  const initial = { opacity: 0, scale: 0.3 };
  const transition = { delay: 0.3, duration: 0.6 };
  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        scale: 1,
      });

      return;
    }

    animation.start({
      opacity: 0,
      scale: 0.3,
    });
  }, [inView, animation]);

  return (
    <>
      <ContentSec inverse={inverse} ref={ref}>
        <Container>
          <ContentRow reverse={reverse}>
            <ContentColumn>
              <TextWrapper>
                <TopLine
                  initial={initial}
                  transition={{ delay: 0.3, duration: 0.6 }}
                  animate={animation}
                >
                  {topLine.text && (
                    <Box>
                      <Box>{topLine.extraText}</Box>
                      {topLine.text}
                    </Box>
                  )}
                </TopLine>

                <ContentHeading
                  initial={initial}
                  transition={transition}
                  animate={animation}
                  inverse={inverse}
                >
                  {headline}
                </ContentHeading>
                <Subtitle
                  initial={initial}
                  transition={{ ...transition, delay: 0.7 }}
                  animate={animation}
                  inverse={inverse}
                >
                  {description}
                </Subtitle>
                {bottomImg ? (
                  <Img
                    initial={initial}
                    transition={{ delay: 1, duration: 0.6 }}
                    animate={animation}
                    alt=""
                    src={bottomImg}
                    maxHeight={"200px"}
                  />
                ) : (
                  <ContentButton
                    initial={initial}
                    transition={{ ...transition, delay: 1 }}
                    animate={animation}
                    inverse={inverse}
                    primary={primary}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {buttonLabel}
                  </ContentButton>
                )}
              </TextWrapper>
            </ContentColumn>
            <ContentColumn
              initial={animation}
              transition={transition}
              animate={animation}
              bigImage={bigImage}
            >
              <ImgWrapper>
                <Img
                  src={img}
                  alt={alt}
                  initial={{ rotate: -2 }}
                  whileHover={{ rotate: 0, scale: 1.02 }}
                  transition={{ duration: 0.5 }}
                />
              </ImgWrapper>
            </ContentColumn>
          </ContentRow>
        </Container>
      </ContentSec>
      <ModalAI show={showModal} onHide={() => setShowModal(false)}>
        <ContentHeadingModal inverse>{headline}</ContentHeadingModal>
        <ImgWrapper>
          <Img src={img} alt={alt} />
        </ImgWrapper>
        {/* <SubtitleTop mt="1 rem">{description}</SubtitleTop> */}
        <SubtitleModal>{longDesc}</SubtitleModal>
        <ButtonContainer>
          {linkMako && (
			<a
                  target="_blank"
                  href={linkMako}
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                  }}
                >
                  <div className="heroneon">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Our MAKO (A.I.)
                  </div>
                </a>
          )}
        </ButtonContainer>
      </ModalAI>
    </>
  );
};

export default ContentAI;
