import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Column, Row } from '../../globalStyles';

export const FooterRow = styled(Row)`
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 10px 30px;
	color: #fff;
	margin: auto;
	max-width: 1280px;
	@media screen and (max-width: 720px) {
		justify-content: center;
	}
`;

export const FooterColumn = styled(Column)`
	margin: 1rem 2rem;
	@media screen and (max-width: 720px) {
		width: 30%;
		text-align: center;
	}
`;

export const SocialIcon = styled.img`
	margin-right: 10px;
	width: 40px;
`;

export const FooterSocialIcon = styled.a`
	color: #fff;
	font-size: 24px;

	@media screen and (max-width: 768px) {
		margin-bottom: 2rem;
	}
`;

export const FooterForm = styled.form`
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 820px) {
		flex-direction: column;
		width: 80%;
	}
`;

export const FooterInput = styled.input`
	padding: 10px 20px;
	border-radius: 2px;
	margin-right: 10px;
	outline: none;
	border: none;
	font-size: 16px;
	border: 1px solid #fff;

	&::placeholder {
		color: #242424;
	}

	@media screen and (max-width: 820px) {
		width: 100%;
		margin: 0 0 16px 0;
	}
`;

export const FooterLine = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 40px auto 0;

	@media screen and (max-width: 820px) {
		flex-direction: column;
	}
`;

export const FooterLogo = styled(Link)`
	color: #fff;
	justify-content: start;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	display: flex;
	flex-direction: column;
	align-items: start;
	margin-bottom: 16px;

	@media screen and (max-width: 768px) {
		align-items: center;
	}
`;

export const WebsiteRights = styled.small`
	color: #fff;
	margin-bottom: 16px;
	@media screen and (max-width: 768px) {
		margin-top: 2rem;
	}
`;

export const FooterIcon = styled.img`
	margin-right: 0.5rem;
	width: 15rem;


`;


export const FooterImageWrapper = styled.div`
	margin-top: 2rem;
	border-radius: 50%;
	background-color: transparent;
	padding: 0px;
`;

export const FooterImage = styled.img`
	width: 100%;
	height: 2rem;
`;


export const FooterTextWrapper = styled.div`
	position: relative;
	padding: 0 0 30px;
	margin-bottom: 2rem;

	&:before {
		width: 60px;
		height: 4px;
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		background-color: #fff;
		transform: translateX(-50%);
	}
`;