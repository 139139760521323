import React, { useEffect } from "react";
import { Container } from "../../../styles/globalStyles_event";
import { Button } from "../../../globalStyles";
import { IoIosCall } from "react-icons/io";

import {
  FeatureText,
  FeatureSection,
  FeatureTitle,
  FeatureWrapper,
  FeatureColumn,
  FeatureImageWrapper,
  FeatureName,
  FeatureImage,
  FeatureTextWrapper,
  FeatureMainText,
  FeatureLinks,
  ButtonContainer,
} from "./EnquiryStyle";
import { enquiry799 } from "../../../data/EnquiryData";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import background from "./cement-texture.jpg";
import "./button.css";

const Enquiry799 = ({ id }) => {
  const initial = {
    scale: 0.2,
    y: 40,
    opacity: 0,
  };

  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1,
        y: 0,
        opacity: 1,
      });
    }
  }, [inView, animation]);

  return (
    <FeatureSection
      id={id}
      inverse
      style={{ backgroundImage: `url(${background})` }}
    >
      <Container ref={ref}>
        <FeatureTextWrapper
          initial={initial}
          animate={animation}
          transition={{ duration: 0.3 }}
        >
          <FeatureMainText inverse>{enquiry799.question}</FeatureMainText>

          <FeatureTitle inverse>{enquiry799.title}</FeatureTitle>
          <FeatureMainText inverse>{enquiry799.text}</FeatureMainText>
          <ButtonContainer>
            <Link to={"/enquiry"}>
              <a
                target="_blank"
                href={"#"}
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                <div className="heroneon">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Quotation
                </div>
              </a>
            </Link>
          </ButtonContainer>

          <FeatureMainText inverse>{enquiry799.text2}</FeatureMainText>
        </FeatureTextWrapper>
        <ButtonContainer>
          <Link to={"/website-seo"}>
            <a href={"#"} target="_blank">
              <button class="glowing-btn1">
                <span class="glowing-txt">
                  S<span class="faulty-letter">E</span>
                  <span class="faulty-letter">O</span>
                </span>
              </button>
            </a>
          </Link>
          {/* <Button big fontBig red>
            <IoIosCall size="2.3rem" /> or Give us a call
          </Button> */}
        </ButtonContainer>
      </Container>
    </FeatureSection>
  );
};

export default Enquiry799;
