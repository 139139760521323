import React from 'react'
import { AIRData } from '../../data/ContentData.js'
import HeroAI from '../../components/AI-service/HeroAI/HeroAI.js'
import FeaturesAI from '../../components/AI-service/FeaturesAI/FeaturesAI.js'
import ContentAI from '../../components/AI-service/ContentAI/ContentAI.js'
import EnquiryAI from '../../components/AI-service/EnquiryQR/EnquiryAI.js'
import { Helmet } from "react-helmet-async";
import UsePageSEO from '../../hook/UsePageSEO.js'
import Transition from '../../hook/Transition.js'

const aipage = () => {
  window.scrollTo(0, 0)

  UsePageSEO({
    title: "Mira Elite | Artificial Intelligent (AI) Services",
    description:'Artificial Intelligent (AI) Services | Intelligent Web services',
    keywords:["AI","Artificial Intelligent", "AI services","Rambo full stack"],
    ogTitle:"Mira Elite | Artificial Intelligent (AI) Services",
    ogDescription:" Artificial Intelligent (AI) Services | Intelligent Web services",
    ogSite_name:"AI service, intelligent website",
    ogUrl:"https://miraelite.com/ai-service",
    ogImage:"https://mira-elite.s3.ap-southeast-2.amazonaws.com/aibg.png"
  })

  return (
    <>
      <Helmet>
        <title>Mira Elite | AI services</title>
        <meta name="description" content="Artificial Intelligent (AI) services" />
        <link rel="canonical" href="/ai-service" />

        </Helmet>
        <Transition />
    <HeroAI />
    <FeaturesAI />
    <div id="events">
				{AIRData.map((contentData, index) => (
					<ContentAI {...contentData} key={index} />
				))}
			</div>
    <EnquiryAI />
    </>)
    
}

export default aipage