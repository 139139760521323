import React from "react";
import Hero from "../../components/Hero/Hero";

import Features from "../../components/HomePage/Features/Features";
import Carousel from "../../components/HomePage/Carousel/Carousel";
import EventsSections from "../../components/HomePage/EventsSection/EventsSection";
import Testimonitals from "../../components/HomePage/Swiper/Testimonitals";
import { Helmet } from "react-helmet-async";
import UsePageSEO from "../../hook/UsePageSEO";
import Transition from "../../hook/Transition";

const HomePage = () => {
  window.scrollTo(0, 0);

  
  UsePageSEO({
    title: "Mira Elite | Auckland, Christchurch",
    description:'Web design, SEO, QR order, AI services, Research',
    keywords:["Web design","SEO","QR order","AI services","Research"],
    ogTitle:"Mira Elite Christchurch | Web design, SEO, QR order, AI services, Research",
    ogDescription:"Web design, SEO, QR order, AI services, Research",
    ogSite_name:"Web design, SEO, AI services",
    ogUrl:"https://miraelite.com/",
    ogImage:"https://globalmira.s3.ap-southeast-2.amazonaws.com/MIRAS.png"
  })
  return (
    <>
    <Transition />
     <Hero />
      <Features />
      <EventsSections />
      {/* <Carousel /> */}
      {/* <Testimonitals /> */}
    </>
  );
};

export default HomePage;
