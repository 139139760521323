export const QrMain = {
	reverse: true,
	inverse: false,
	topLine: {
		// text: 'Free',
		// location: 'Park City, Utah',
	},
	headline: 'Find the best events',
	description:
		'Welcome New Year with Wondergarden on waterfront. Celebrate with world-class international acts, roaming performers, children’s performers and entertainment',
	buttonLabel: 'Find out more',
	imgStart: 'start',
	img: './assets/heromain1.jpg',
	start: 'true',
};

const QROne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'First',
		extraText: 'New',
	},
	headline: 'QR code for ordering via scan',
	description:
		'Guests dining in? Allow them to scan and place orders.',
	longDesc:`Your customers can enjoy all the familiar aspects of dining in: browsing the menu, selecting their favorite dishes, and settling the bill. However, we have introduced a new feature called Scan to Order, which allows them to do all of this conveniently and securely from their mobile devices. By leveraging a cutting-edge contactless ordering technology, you can improve table turnover rates with a leaner staff while prioritizing the safety of your guests' dining experience.`,
	buttonLabel: 'Learn more',
	imgStart: 'start',
	img: './QRpage/QRorder.jpeg',
	start: 'true',
};

const QRTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'Approach',
		extraText: 'New',
	},
	headline: 'Digital Loyalty Card',
	description:
		'Boost Your Brand with Durable, High-Quality Loyalty Cards',
		longDesc:`The digital loyalty platform combines the traditional concept of "Buy X, Get Y" loyalty cards with a contemporary mobile application. This enables businesses to provide a fully contactless and interactive stamp card loyalty program.`,
	buttonLabel: 'Find out more',
	imgStart: 'start',
	img: './QRpage/Digital.jpeg',
	start: 'true',
};

const QRThree = {
	reverse: true,
	inverse: true,
	topLine: {
		extraText: 'New',
		text: 'Exclusive',
	},
	headline: 'Happy Hours Booking',
	description:
		'A revolutionary booking software designed to simplify and streamline the reservation process for businesses like yours.',
	longDesc:`Mindbody is the leading platform that generates exceptional revenue for fitness, wellness, and beauty businesses. With our unparalleled consumer network and state-of-the-art tools, we have what it takes to help you expand and thrive.`,
	buttonLabel: 'Find out more',

	imgStart: '',
	img: './QRpage/Booking.jpeg',
	start: 'true',
};



export const QRData = [QROne, QRTwo, QRThree];

const AIOne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'First',
		extraText: 'AI service',
	},
	headline: 'Designed and trained an AI, later deployed in Cloud',
	description:
		'Do you want to enhance the intelligence of your website by incorporating AI technology? At MIRA ELITE, we specialize in building, training, and deploying AI models using advanced techniques such as Support Vector Machine, Artificial Neural Network, Random Forest, and Hybrid models',
	longDesc:`We selected a sample containing at least 1000 observations. First, we performed data cleaning and modeling using Python's Pandas library to input the data. Then, we utilized Logit Regression to identify significant variables, removing any insignificant ones from the model.

	Next, we employed Scikit-Learn to train the model using various algorithms, including Support Vector Machine, Random Forest, and Artificial Neural Network. We evaluated the models using four criteria: Confusion Matrix, Misclassification Cost, AUC Ratio, K-S statistic, and Gini ratio. The model with the highest evaluating ratio will be chosen for deployment.
	
	Finally, we deployed the best model in the cloud and created an API to connect it with the website's backend for control. With AI integration, the website will become more intelligent.`,
	buttonLabel: 'Learn more',
	imgStart: 'start',
	img: './ai-page/mako.jpeg',
	bottomImg: '',
	linkTo: '/download',
	start: 'true',
	linkMako:'https://mako.lokalai.com/'
};

const AITwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'Second',
		extraText: 'AI Service',
	},
	headline: 'Interest Scoring Model',
	description:
		`We use the Interest Scoring Model to quantify people's interests into numerical values. This approach allows our AI model to effectively learn, train, and make informed decisions.`,
	longDesc:`The Interest Scoring Model has the primary purpose of assigning numerical values to people's attributes. People have different interests at different times of the day. For example, in the morning, they are likely to have breakfast, later on, they may want to have coffee, in the afternoon, they may enjoy shopping, and in the evening, they may want to explore new places. Utilizing big data, our AI model will learn and analyze people's behavior to develop these numerical scores. The model will be trained and deployed in the cloud. Subsequently, any website can request users' interest scoring model based on factors such as time, location, and gender. This score can then be used to search for suitable products in their database.`,
	buttonLabel: 'Learn more',
	imgStart: 'start',
	img: './ai-page/mako2.jpeg',
	start: 'true',
	linkMako:'https://mako.lokalai.com/'
};

const AIThree = {
	reverse: true,
	inverse: true,
	topLine: {
		extraText: 'Three',
		text: 'AI service',
	},
	headline: 'MERN stack with AI support',
	description:
		'A revolutionary web/app development platform designed to enhance web design with AI support.',
	longDesc:`The Rambo Full Stack is specifically engineered to enhance the MERN (MongoDB, Express, React, Node.js) stack by incorporating advanced AI capabilities. To begin, we establish the website as a MERN full stack, utilizing React for the front end, Node.js for the back end, MongoDB for the database, and the Express framework. Following this, we employ Python to develop the AI model. Afterwards, an API request is sent from the website to the AI cloud, which then produces an interest score. Utilizing this score, the website will conduct a search within the database to identify the most suitable product for users at that particular moment. This approach significantly enhances the website's intelligence.`,
	buttonLabel: 'Learn more',

	imgStart: '',
	img: './ai-page/Rambo.jpeg',
	start: 'true',
	linkMako:''
};



export const AIRData = [AIOne, AITwo, AIThree];
