import React from "react";
import Pricing from "../../components/Pricing/Pricing";
import Content from "../../components/Content/Content";
import HeroSEO from "../../components/SEOPage/HeroSEO/HeroSEO";
import { seoFour, seoOne, seoThree, seoTwo } from "../../data/seoData";
import { Helmet } from "react-helmet-async";
import UsePageSEO from "../../hook/UsePageSEO";
import FeaturesSEO from "../../components/SEOPage/FeaturesSEO/FeaturesSEO";
import Transition from "../../hook/Transition";

const seo = () => {
  window.scrollTo(0, 0);


  UsePageSEO({
    title: "Mira Elite | SEO and web care plan",
    description:'SEO and Web care plan',
    keywords:["Web design","SEO", "Web maintain","Web care", "Social Media Plan"],
    ogTitle:"Mira Elite | SEO and web care plan",
    ogDescription:" SEO and web care plan, Social Media plan",
    ogSite_name:"SEO and web care plan",
    ogUrl:"https://miraelite.com/website-seo",
    ogImage:"https://mira-elite.s3.ap-southeast-2.amazonaws.com/bg_seo.png"
  })
  return (
    <>
      <Helmet>
        <title>Mira Elite | SEO Services</title>
        <meta name="description" content="SEO Services Christchurch" />
        <link rel="canonical" href="/website-seo" />
      </Helmet>
      <Transition />

      <HeroSEO />
      {/* <FeaturesSEO /> */}
      <Content {...seoOne} />
      <Content {...seoTwo} />
      <Content {...seoThree} />
      <Content {...seoFour} />
      <Pricing />
    </>
  );
};

export default seo;
