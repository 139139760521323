import React from "react";
import { GiPartyFlags, GiMicrophone } from "react-icons/gi";
import { TiGroupOutline } from "react-icons/ti";
import { SiEsotericsoftware } from "react-icons/si";

const iconStyle = (Icon) => <Icon size="3rem" color="red" />;

export const enquiryQR = {
  question: "That all sounds great, right?",
  title:
    "But what does having a QR Menu, Digital Loyalty and Booking actually mean for your business?",
  text: "Our software offers an affordable and cash flow friendly monthly subscription that provides you software to start with. With our subscription, you can make changes and updates to your software without incurring any unexpected additional costs. Plus, you'll never have to purchase software again.",
};

export const enquiryAI = {
  question: "Would you like to make your website more intelligent?",
  title: "Using our AI services with an independent Interest Scoring Model.",
  text: "Our software provides an affordable quotation that includes a comprehensive suite of AI services to kickstart your website. With our AI service, you can enhance your website's intelligence and enable it to interact more effectively with your customers. This enhanced user experience will encourage your customers to spend more time on your website.",
};

export const enquiry799 = {
  question: "Would you like to build a Efficient Website?",
  title: "Using our Web Developemt services",
  text: `We take pride in offering a web development service that is not only professional and of the highest quality, but also brings tremendous value to your business. We understand the importance of a dynamic and responsive website in today's digital landscape, and we are dedicated to providing you with a seamless online presence that stands out among your competitors.`,
  text2: `In addition, we offer comprehensive SEO services to boost your website's visibility and help you rank higher in search engine results.  We also provide a Web Care plan service that ensures your website is always online and performing at its best.`,
};
