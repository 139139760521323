import React, { Suspense, lazy, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";

import { Redirect } from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
import Signup from "./pages/SignupPage/Signup";
import GlobalStyle from "./globalStyles";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import qrpage from "./pages/QRPage/qrpage";
import seo from "./pages/SEOpage/seo";
import website799 from "./pages/Website799/website799";
import aipage from "./pages/AIPage/ai-page";
import research from "./pages/Research/research";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import PopUpButton from "./components/PopUpButton/PopUpButton";
import notfound from "./pages/Notfound/notfound";
import { equery } from "./pages/Equery/equery";
import BacktoTop from "./components/BacktoTop/BacktoTop";
import UserOnline from "./hook/userOnlineLocation/userOnline";

function App() {
  return (
    <>
      <Router>
        <GlobalStyle />
        <Navbar></Navbar>

        <BacktoTop />
        {/* <Callus /> */}
        <div>
          <PopUpButton></PopUpButton>
        </div>

        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/sign-up" exact component={Signup} />
          <Route path="/qr-order" exact component={qrpage} />
          <Route path="/website-seo" exact component={seo} />
          <Route path="/web799" exact component={website799} />
          <Route path="/ai-service" exact component={aipage} />
          <Route path="/research" exact component={research} />
          <Route path="/enquiry" exact component={equery} />
          <Route exact path="/404" component={notfound} />
          <Redirect to="/404" />
        </Switch>
        <Footer />
        {/* <UserOnline /> */}
      </Router>
    </>
  );
}

export default App;
