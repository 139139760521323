import React from "react";
import "./notfound.css";
import notfoundpic from "./404.png";
import { Helmet } from "react-helmet-async";
import Transition from "../../hook/Transition";

const notfound = () => {
    window.scrollTo(0, 0);

    return (
        <>
            <Helmet>
                <title>404</title>
                <meta name="description" content="Not found" />
                <link rel="canonical" href="/404" />

            </Helmet>
            <Transition />
            <div className="divnotfound">
                <h1 style={{
                    textDecoration: "none",
                    fontSize: "4rem",
                    fontWeight: 500,
                    color: "#fff",
                }}>404</h1>

                <img src={notfoundpic} width={600} />
                <a
                    href="/"
                    style={{
                        textDecoration: "none",
                        fontSize: "1.5rem",
                        fontWeight: 500,
                        color: "#fff",
                    }}
                >
                    Back to Home
                </a>
            </div>
        </>
    );
};

export default notfound;
