import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { pink, orange } from '../../../Colors';

export const TestiSection = styled.div`
	padding: 140px;
	position: relative;
	margin: 0 auto;
	background-color: ${({ inverse }) => (inverse ? 'white' : '#020305')};
	border: none;

	@media screen and (max-width: 768px) {
		padding: 50px 10px;
	}
`;

export const TestiTitle = styled.h2`
	text-align: center;
	font-size: clamp(2.1rem, 7vw, 3rem);

	line-height: 1.06;
	letter-spacing: 0.4rem;
	margin: auto;
	color: ${({ inverse }) => (inverse ? 'white' : '#020305')};
`;

export const TestiMainText = styled.p`
	text-align: center;
	font-size: clamp(1rem, 2vw, 1.2rem);
	margin: 2rem auto 0;
	width: 70%;
	color: ${({ inverse }) => (inverse ? 'white' : 'white')};
`;

export const TestiTextWrapper = styled(motion.div)`
	position: relative;
	padding: 0 0 20px;
	margin-bottom: 4rem;
	color: ${({ inverse }) => (inverse ? 'black' : 'white')};
`;

