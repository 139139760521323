import React, { useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

import { motion } from "framer-motion";
import Modal799 from "../Modal799/Modal799";
import {
  ClientImageModal,
  ClientText,
  ClientTextBold,
  ClientTextBold1,
  ClientTextWrapper,
  ClientTitle,
  ShowcaseStars,
} from "./ShowCaseStyles";
import { Testi799 } from "../../../data/TestimonialsData";
import "./quotes.css";
import "./buttonflash_white.css";
import { Stars } from "@mui/icons-material";

const Section = styled.section`
  // min-height: 100vh;
  width: 100vw;
  margin: 0 auto;
  background-color: ${(props) => props.theme.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  & > *:first-child {
    animation-duration: 10s;

    @media (max-width: 30em) {
      animation-duration: 10s;
    }
  }
  & > *:last-child {
    animation-duration: 20s;
    @media (max-width: 30em) {
      animation-duration: 20s;
    }
  }
`;
const move = keyframes`
0%{ transform: translateX(100%)   };
100%{ transform: translateX(-100%)   }

`;

const Row = styled.div`
  // background-color: lightblue;
  white-space: nowrap;
  box-sizing: content-box;
  margin: 2rem 0;
  display: flex;

  animation: ${move} linear infinite ${(props) => props.direction};
`;
const ImgContainer = styled.div`
  width: 18rem;
  margin: 0 1rem;
  background-color: ${(props) => props.theme.body};
  border: 1px solid lightgray;
  padding: 2rem 1rem;
  margin-bottom: 10rem;
  border-radius: 20px;
  cursor: pointer;

  @media (max-width: 48em) {
    width: 18rem;
  }
  @media (max-width: 30em) {
    width: 18rem;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  background-color: ${(props) => props.theme.text};
  border: 2px solid ${(props) => `rgba(${props.theme.bodyRgba},0.5)`};

  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  span {
    font-size: ${(props) => props.theme.fontsm};
    color: ${(props) => `rgba(${props.theme.bodyRgba},0.5)`};
    font-weight: 600;
    line-height: 1.5rem;

    h1 {
      font-size: ${(props) => props.theme.fontmd};
      color: ${(props) => props.theme.body};
      font-weight: 600;
      width: 80%;
    }
    @media (max-width: 30em) {
      font-size: ${(props) => props.theme.fontsm};
    }
  }
`;

const Price = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 1rem;
    height: auto;
  }
`;

const ImgWrapper = styled(motion.div)`
  display: flex;
  max-height: 600px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 32px 4px #dfdfdf;
  border-radius: 1rem;
  position: relative;
  padding: 1rem;
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "#fafeff"};
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 0 auto;
  &:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background-image: url("./images/hero-pattern-bg.png");
    background-size: 100% auto;
    object-fit: cover;
  }
`;

const FeatureText2 = styled.p`
  margin: 1rem 0 auto;
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.73;
  letter-spacing: 0.5px;
  color: #626881;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const FeatureTextWrapper = styled.div`
  position: relative;
  padding: 0 0 20px;
  margin-bottom: 4rem;

  &:before {
    width: 40px;
    height: 2px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #ef4b6c;
    transform: translateX(-50%);
  }
`;

export const FeatureTitle = styled.h2`
  text-align: center;
  font-size: clamp(2.5rem, 6vw, 4.5rem);
  line-height: 1.06;
  letter-spacing: 0.4rem;
  margin: auto;
`;

const NftItem = ({ img, passRef, title, desc }) => {
  let play = (e) => {
    passRef.current.style.animationPlayState = "running";
  };
  let pause = (e) => {
    passRef.current.style.animationPlayState = "paused";
  };
  return (
    <ImgContainer onMouseOver={(e) => pause(e)} onMouseOut={(e) => play(e)}>
      <ImgWrapper>
        <img
          src={img}
          alt="The Weirdos"
          style={{
            maxWidth: "80px",
            width: "80px",
            height: "auto",
            margin: "1rem",
            filter: "grayscale(100%)"
          }}
        />
      </ImgWrapper>
      {/* <ClientTextWrapper>
        <ClientTextBold1 >{title}</ClientTextBold1>
        <ClientText >{desc}</ClientText>
      </ClientTextWrapper> */}
      <div class="wrapper">
        <h1 class="demo-1">
          {title}
        </h1>
        <p class="demo-2">
          {desc}
        </p>
      </div>
      <ShowcaseStars>
        <img src={"/web799/stars.svg"} style={{ width: "30px" }} />
        <img src={"/web799/stars.svg"} style={{ width: "30px" }} />
        <img src={"/web799/stars.svg"} style={{ width: "30px" }} />
        <img src={"/web799/stars.svg"} style={{ width: "30px" }} />
        <img src={"/web799/stars.svg"} style={{ width: "30px" }} />
      </ShowcaseStars>
    </ImgContainer>
  );
};

const Showcase799 = () => {
  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);
  const [showModal, setShowModal] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);

  const handleClick = (index) => {
    setShowModal(true);
    setSelectedImage(index);
    console.log({ index });
  };
  return (
    <>
      <FeatureTextWrapper>
        <FeatureTitle>Testimonials</FeatureTitle>
        <FeatureText2>Our clients talk about us</FeatureText2>
      </FeatureTextWrapper>

      <Section id="showcase">
        <Row direction="none" ref={Row1Ref}>
          {Testi799.map((testi, testiIndex) => (
            <div
              onClick={() => {
                handleClick(testiIndex);
              }}
            >
              <NftItem
                img={testi.image}
                title={testi.bigTitle}
                desc={testi.desc}
                number={testi.stars}
                price={1}
                passRef={Row1Ref}
              />
            </div>
          ))}
        </Row>

        {/* <Row direction="reverse" ref={Row2Ref}>
          <NftItem
            img={"/web799/regal_logo.png"}
            number={888}
            price={1.2}
            passRef={Row2Ref}
          />
          <NftItem
            img={"/web799/diamond_logo.png"}
            number={211}
            price={3.2}
            passRef={Row2Ref}
          />
          <NftItem
            img={"/web799/taupo_lo.png"}
            number={455}
            price={1.8}
            passRef={Row2Ref}
          />
          <NftItem
            img={"/web799/cady21.png"}
            number={456}
            price={5.1}
            passRef={Row2Ref}
          />
          <NftItem
            img={"/web799/towerlogo.png"}
            number={865}
            price={3.7}
            passRef={Row2Ref}
          />
        </Row> */}
      </Section>

      <Modal799 show={showModal} onHide={() => setShowModal(false)}>
        <div
          style={{
            background: "#aaa",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ClientImageModal src={Testi799[selectedImage].image} />
          <div className="quotes">
            <h2 className="h2-quote">{Testi799[selectedImage].name}</h2>
            <p className="p-quote">{Testi799[selectedImage].desc}</p>
            <h4 className="h6-quote">{Testi799[selectedImage].bigTitle}</h4>
          </div>
          <a
            target="_blank"
            href={Testi799[selectedImage].link}
            style={{
              textDecoration: "none",
              color: "#fff",
            }}
          >
            <div className="heroneon_white">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Visit Website
            </div>
          </a>
        </div>
      </Modal799>
    </>
  );
};

export default Showcase799;
