export const heroOne = {
	id: 'about',
	inverse: true,
	bigImage: true,
	topLine: {
		text: 'IMusic',
		extraText: 'New',
	},
	headline: 'Never Stop Listening',
	description:
		'Enjoy audio content where, when and how you want it. IMusic Available On Hundreds of Portable Devices',
	buttonLabel: 'Sign Up Now',
	imgStart: 'start',
	img: './images/art2.jpg',
	bottomImg: './images/recording.svg',
	start: 'true',
};

export const heroTwo = {
	id: 'services',

	reverse: true,

	lightBg: false,
	topLine: {
		text: 'Best collection',
		extraText: '',
	},
	headline: 'Beats that touch your heart',
	description:
		'Choose your best music choice with huge library Of our top chart songs nad Favorite singers',
	buttonLabel: 'Learn More',
	linkTo: '/more',
	imgStart: '',
	img: './images/hero.jpg',
	bottomImg: '',
	alt: 'Vault',
	start: 'true',
};

export const heroThree = {
	id: 'products',

	bigImage: true,
	smallSection: true,
	inverse: true,
	topLine: {
		text: 'Subscribe',
		extraText: '',
	},
	headline: 'Your Music Partner',
	description:
		'We help you understand the music of design and drive transformation from inside out',
	buttonLabel: 'Download',
	linkTo: '/download',
	imgStart: '',
	img: './images/music.png',
	bottomImg: '',
	alt: 'Vault',
	start: 'true',
};

export const pricingData = [
	{
		title: 'Green Website',
		price: '$99 / month',
		numAcc: 'For businesses that want to keep their website operational.',
		features: [
			'All Plan inclusions',
			'30 minutes support time',
			'Quick fixes & Detailed monthly reports',
			'Performance optimisation',
		],
		nonfeatures: [
			'eCommerce support',
			'Multisite support',
			'Quality assurance testing',
			'SEO & analytics reporting',
			'Support rollover',
		],
	},
	{
		title: 'Silver Website',
		price: '$149 / month',
		numAcc: 'For businesses that want to optimise their website.',
		features: [
			'All Plan inclusions',
			'120 minutes support time',
			'PageSpeed optimisation',
			'eCommerce support',
			'Multisite support',
			'Quality assurance testing',
			'SEO & analytics reporting',			
		],
		nonfeatures: [
			'Support rollover',
		],
	},
	{
		title: 'Gold Website ',
		price: '$199 / month',
		numAcc: 'For businesses that want to scale and optimise their website',
		features: [
			'All Plan inclusions',
			'240 minutes support time',
			'Web vitals optimisation',
			'eCommerce support',
			'Multisite support',
			'Quality assurance testing',
			'SEO & analytics reporting',
			'Unused support time rolls over',
		],
		nonfeatures: [
		],
	},

];
