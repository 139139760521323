export const clientsData = [
	[{ name: 'diamond_logo' }],
	[{ name: 'cady21' }, { name: 'apple' }],
	[{ name: 'towerlogo' }, { name: 'youtube' }, { name: 'google' }],
	[{ name: 'regal_logo' }, { name: 'netflix' }],
	[{ name: 'taupo_lo' }],
];


export const clientsData799 = [
	{
		name: 'Diamond Nail Blenheim',
		image: '/web799/diamond_logo.png',
		desc:`Where Quality Meets Efficiency. Here you will be greeted by our team of experts who are here to provide you with a professional experience.`,
		link:'https://diamondnailsblenheim.com/',
		class: 'big',
	},
	{
		name: 'Caddyshack City',
		image: '/web799/cady21.png',
		desc:`Located in our indoor, all-weather venue just a few minutes drive from Christchurch’s centre . Each of our 18 holes incorporates a different theme with some amazing scale models, and each offers different skill challenges and fun.`,
		link:'https://caddyshack.co.nz/',
		class: 'big',
	},
	{
		name: 'Tower Nails & Beauty',
		image: '/web799/towerlogo.png',
		desc:'This is one of the biggest and well-equipped nail salons in Christchurch with updated techniques & quality products. We offer nail treatments, eyelashes, tinting, and waxing at the most affordable price',
		link:'https://towerbeauty.co.nz/',
		class: 'big',
	},
	{
		name: 'Regal Nails',
		image: '/web799/taupo_lo.png',
		desc:'This is one of the biggest and well-equipped nail salons in Christchurch with updated techniques & quality products. We offer nail treatments, eyelashes, tinting, and waxing at the most affordable price',
		link:'https://regalnail.co.nz/',
		class: 'big',
	},
	{
		name: 'Taupo Luxury Nail',
		image: '/web799/regal_logo.png',
		desc:'Taupo Luxury Nails Is Proud To Be One Of The Best Nail Salons, Located Conveniently In Taupo. Our Nail Salon Will Create An Ideal Space For You To Enjoy The Most Comfortable Moments.',
		link:'https://regalnail.co.nz/',
		class: 'big',
	},
	{
		name: 'Viet EMart',
		image: '/web799/vietemart2.png',
		desc:'All the assets you need, in one place. Shop clothes, accessories, and more from independent sellers around the world.',
		link:'https://vietemart.com/',
		class: 'big',
	},
	
];
