import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Column,
  MainHeading,
  MainHeading_nail,
  Row,
  TextWrapper,
} from "../../../globalStyles";
import { BiPlayCircle } from "react-icons/bi";
import {
  HeroSection,
  ButtonContainer,
  HeroSEOTextWrapper,
  HeroScrollLinks,
} from "./HeroSEOStyles";
import "./textbg.css";
import "./buttonflash.css";

const HeroSEO = () => {
  return (
    <HeroSection id="hero">
      <Row justify="center" align="center" height="100%" padding="2rem">
        <Column align="center" mb="5%">
          <TextWrapper color="white" size="clamp(1rem,3vw,1.2rem)" margin="0 0 1rem">
            SEO Agency Christchurchs
          </TextWrapper>
          <div class="text-container2">
            <MainHeading_nail maxWidth="600px" margin="0 0 2rem">
              Make website discoverable online{" "}
            </MainHeading_nail>
          </div>
          <TextWrapper
            color="white"
            size="clamp(1rem,3vw,1.2rem)"
            margin="0 0 2rem"
            lineheight="1.5"
            maxWidth="800px"
            align="center"
          >
            Having a visually appealing and easily discoverable website is
            essential in today's digital landscape. Search engine optimization
            (SEO) is crucial for the success of your business.
          </TextWrapper>
          <ButtonContainer>
            <HeroScrollLinks
              spy={true}
              duration={500}
              smooth={true}
              exact="true"
              offset={-60}
              to={"discover"}
            >
              <a
                target="_blank"
                href={"#"}
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                <div className="heroneon">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Discover More
                </div>
              </a>
            </HeroScrollLinks>
          </ButtonContainer>
        </Column>
      </Row>
    </HeroSection>
  );
};

export default HeroSEO;
