import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { pink, orange } from '../../../Colors';
import { Link as LinkScroll } from 'react-scroll';


export const FeatureSection = styled.div`
	padding: 140px;
	position: relative;
	margin: 0 auto;

	@media screen and (max-width: 768px) {
		padding: 70px 0;
		margin-top: 0rem;
	}
`;

export const FeatureTitle = styled.h2`
	text-align: center;
	font-size: clamp(2.5rem, 6vw, 4.5rem);
	line-height: 1.06;
	letter-spacing: 0.4rem;
	margin: auto;

	@media screen and (max-width: 960px) {
		margin: 0;
	}

`;

export const FeatureTextWrapper = styled.div`
	position: relative;
	padding: 0 0 20px;
	margin-bottom: 4rem;

	&:before {
		width: 40px;
		height: 2px;
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		background-color: #ef4b6c;
		transform: translateX(-50%);
	}
`;

export const FeatureWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	/* flex-wrap: wrap; */
	margin-top: 4rem;
	grid-gap: 4rem;

	@media screen and (max-width: 960px) {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 0.4rem;
		grid-row-gap: 3rem;
		/* grid-gap: 3rem; */
	}

	@media screen and (max-width: 568px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const FeatureColumn = styled.div`
	/* max-width: 33%; */
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 960px) {
		/* max-width: 50%; */
	}
`;

export const FeatureImageWrapper = styled.div`
	margin-bottom: 0.5rem;
	border-radius: 50%;
	&.one {
		background: linear-gradient(130deg, #b70b0d 0%, #ffb401 100%);
	}
	&.two {
		background: linear-gradient(220deg, #ffb401 0%, #C2240b 100%);
	}
	&.three {
		background: linear-gradient(130deg, #ffb401 0%, #C2240b 100%);
	}
	&.four {
		background: linear-gradient(130deg, #ff0000 0%, #ffb401 100%);
	}
	&.five {
		background: linear-gradient(130deg, #ffb401 0%, #b70b0d 100%);
	}
	&.six {
		background: linear-gradient(220deg, #ffb401 0%, #b70b0d 100%);
	}

	padding: 30px;
`;
export const FeatureName = styled.h3`
	font-weight: 600;
	font-size: 1.3rem;
	letter-spacing: 2px;

	@media screen and (max-width: 768px) {
		font-weight: 400;
		font-size: 1rem;
		letter-spacing: 1.3px;
		text-align: center;
	}
`;
export const FeatureText = styled.p`
	margin: 1rem 0 auto;
	text-align: center;
	font-size: 0.9rem;
	line-height: 1.73;
	letter-spacing: 0.5px;
	color: #626881;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const FeatureText2 = styled.p`
	margin: 1rem 0 auto;
	text-align: center;
	font-size: 0.9rem;
	line-height: 1.73;
	letter-spacing: 0.5px;
	color: #626881;

	@media screen and (max-width: 768px) {
		display: block;
	}
`;


export const FeatureLinks = styled(LinkScroll)`

	text-decoration: none;

	&.active {
		border-bottom: 2px solid #ebc214;
	}
	&:hover {
		border-bottom: 2px solid ${pink};
	}

	@media screen and (max-width: 960px) {
		text-align: center;
		padding: 0rem;
		// width: 100%;
		display: table;

		&:hover {
			color: #4b59f7;
			transition: all 0.3s ease;
		}
	}
`;