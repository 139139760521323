import React from 'react'
import HeroR from '../../components/Research/HeroR/HeroR'
import FeaturesR from '../../components/Research/FeaturesR/FeaturesR'
import { Helmet } from 'react-helmet-async'
import UsePageSEO from '../../hook/UsePageSEO'
import Transition from '../../hook/Transition'

const research = () => {
  window.scrollTo(0, 0)


  UsePageSEO({
    title: "Mira Elite | Research Services",
    description:'Research service, information, big data',
    keywords:["Research","information", "big data"],
    ogTitle:"Mira Elite | Research Services",
    ogDescription:" Research service, information, big data",
    ogSite_name:"Research Services",
    ogUrl:"https://miraelite.com/research",
    ogImage:"https://mira-elite.s3.ap-southeast-2.amazonaws.com/research.png"
  })

  return (
    <>
      <Helmet>
        <title>Mira Elite | Research Services</title>
        <meta name="description" content="Research Services Christchurch" />
        <link rel="canonical" href="/research" />
      </Helmet>
      <Transition />
    <HeroR />
    <FeaturesR />
    </>
  )
}

export default research