export const Testi799 = [
	{
		name: 'Diamond Nail Blenheim',
		image: '/web799/diamond_logo.png',
		bigTitle:'Professionalism, Quality, Resposiveness, Value',
		desc:`I have 4 website with Mira ELite, they are very hands on with finding solutions and give ideas on updating very old out dated websites`,
		link:'https://diamondnailsblenheim.com/',
		stars: 4,
	},
	{
		name: 'Caddyshack City',
		image: '/web799/cady21.png',
		bigTitle:'Professionalism, Quality, Value',
		desc:`MiraElite personnel were so helpful and patient. I enjoyed the experience of working with them and their professional and helpful manner was much appreciated.`,
		link:'https://caddyshack.co.nz/',
		stars: 5,
	},
	{
		name: 'Tower Nails & Beauty',
		image: '/web799/towerlogo.png',
		bigTitle:'Resposiveness, Value',
		desc:'Excellent service, recommended company',
		link:'https://towerbeauty.co.nz/',
		stars: 5,
	},
	{
		name: 'Regal Nails',
		image: '/web799/taupo_lo.png',
		bigTitle:'Quality, Resposiveness, Value',
		desc:'Having been customers of Mira Elite for 2 years. Their teams have proven to be knowledgeable, honest and passionate about our business.',
		link:'https://regalnail.co.nz/',
		stars: 5,
	},
	{
		name: 'Taupo Luxury Nail',
		image: '/web799/regal_logo.png',
		bigTitle:'Professionalism',
		desc:'The best website experience for small business, no hidden costs, freindly and helpful team and best of all they want to undestand your business and form genuine business relationship. It feels amazing to get easy support for what we need, and they are always willing to give somthing a go!',
		link:'https://regalnail.co.nz/',
		stars: 5,
	},
	{
		name: 'Viet EMart',
		image: '/web799/vietemart2.png',
		bigTitle:'Professionalism, Quality, Value',
		desc:'The team have been amazing and we are stoked with our new website and Vietemart. Highly recommended',
		link:'https://vietemart.com/',
		stars: 5,
	},
];

export const sliderSettings = {
	arrows: false,
	slidesToShow: 3,
	focusOnselect: false,
	accessability: false,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2,
			},
		},

		{
			breakpoint: 900,
			settings: {
				slidesToShow: 1,
			},
		},
	],
};
