import styled from "styled-components";

export const ClientSection = styled.div`
  padding: 200px 0;
  // position: relative;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    padding: 70px 0;
    margin-top: 0rem;
  }
`;

export const ClientTextWrapper = styled.div`
  // position: relative;
  padding: 0 0 20px;
  margin-bottom: 4rem;
  // background: red;
  display: flex;
  flex-direction: column;
  aligh-items: center;

  &:before {
    width: 40px;
    height: 2px;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    background-color: #ef4b6c;
    transform: translateX(-50%);
  }
`;

export const ClientTitle = styled.h2`
  text-align: center;
  font-size: clamp(2.5rem, 6vw, 4.5rem);
  line-height: 1.06;
  letter-spacing: 0.4rem;
  margin: auto;
`;

export const ClientText = styled.p`
  margin-bottom: 2rem;
  display: inline-block;

  text-align: center;
  font-size: 0.9rem;
  line-height: 1.73;
  letter-spacing: 0.5px;
  color: #626881;


  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const ClientTextBold1 = styled.p`
display: flex;
flex-direction: column;
  margin: 1rem 0 auto;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.73;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #000;
  max-width: 80%;
  // border: 1px solid red;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;


export const ClientImage = styled.img`
  width: 90px;
  filter: grayscale(100%);
  @media screen and (max-width: 1100px) {
    // width: clamp(80px, 40vw, 400px);
    // height: 80%;
  }
`;

export const ClientImageModal = styled.img`
  height: 100px;
  // filter: grayscale(100%);
  // border: 1px solid red;
  padding-top: 2rem;
  margin: 1rem;
  @media screen and (max-width: 1100px) {
    max-width: 350px;
  }
`;
// export const ClientWrapper = styled.div`
//   padding: 10px 40px;
//   border-radius: 1rem;
//   height: 110px;
//   width: 100%;
//   box-shadow: 0 0 32px 8px #dfdfdf;
//   background-color: #fafeff;
//   margin: 0.4rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   @media screen and (max-width: 1100px) {
//     display: flex;
//     flex-direction: column;
//     margin: 0rem;
//   }
// `;

export const ShowcaseStars = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  @media screen and (max-width: 1100px) {
    margin: 2rem;
  }
`;
