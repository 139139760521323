import { useEffect } from "react";

const UsePageSEO = ({
  title,
  description,
  keywords = [],
  ogTitle,
  ogDescription,
  ogSite_name,
  ogImage,
  ogUrl,
}) => {
  useEffect(() => {
    document.title=title
    
    setMetaTag('name','description',description)
    setMetaTag('name','keywords',keywords)

    setMetaTag('property','og:title',ogTitle || title)
    setMetaTag('property','og:description',ogDescription || description)
    setMetaTag('property','og:image',ogImage)
    setMetaTag('property','og:url',ogUrl || window.location.href)
    setMetaTag('property','og:site_name',ogSite_name)

    setMetaTag('name','twitter:card',"summary_large_image")
    setMetaTag('name','twitter:title',ogTitle || title)
    setMetaTag('name','twitter:description',ogDescription || description)
    setMetaTag('name','twitter:image',ogImage)
    setMetaTag('name','twitter:url',ogUrl || window.location.href)
    setMetaTag('name','twitter:site',ogSite_name)
    return () => {

        //any kind of clean up here
    }


  }, [
    title,
    description,
    keywords,
    ogTitle,
    ogDescription,
    ogSite_name,
    ogImage,
    ogUrl,
  ])

  const setMetaTag = (attr, key, content) => {
    if(content) {
        let element = document.querySelector(`meta[${attr}="${key}"]`)
        if (!element) {
            element = document.createElement('meta')
            element.setAttribute(attr, key)
            document.head.appendChild(element)
        }

        element.setAttribute('content',content)
    }
  }
};

export default UsePageSEO