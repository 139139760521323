import React, { useEffect, useState } from "react";
import { Container } from "../../../styles/globalStyles_event";
import {
  FeatureText,
  FeatureSection,
  FeatureTitle,
  FeatureWrapper,
  FeatureColumn,
  FeatureImageWrapper,
  FeatureName,
  FeatureImage,
  FeatureTextWrapper,
  FeatureMainText,
  FeatureLinks,
} from "./FeaturesStyles";
import { featuresData } from "../../../data/FeaturesData";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import Modal from "../../HomePage/Modal/Modal";

const Features = ({ id }) => {
  const initial = {
    scale: 0.2,
    y: 40,
    opacity: 0,
  };

  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1,
        y: 0,
        opacity: 1,
      });
    }
  }, [inView, animation]);

  const [showModal, setShowModal] = useState(false);

  const [selectedImage, setSelectedImage] = useState(0);

  const handleClick = (index) => {
    setShowModal(true);
    setSelectedImage(index);
    console.log({ index });
  };

  return (
    <div>
      <FeatureSection id="ourservice">
        <Container ref={ref}>
          <FeatureTextWrapper
            initial={initial}
            animate={animation}
            transition={{ duration: 0.3 }}
          >
            <FeatureTitle>{featuresData.title}</FeatureTitle>
            <FeatureMainText>{featuresData.text}</FeatureMainText>
          </FeatureTextWrapper>
          <FeatureWrapper>
            {featuresData.content.map((el, index) => (
              // <div
              //   onClick={() => {
              //     handleClick(index);
              //   }}
              // >
                <FeatureColumn
                  initial={initial}
                  animate={animation}
                  transition={{ duration: 0.7 + index * 0.1 }}
                  key={index}
                >
                  <Link to={el.link}>
                  <FeatureImageWrapper>
                    <FeatureImage src={el.image} alt="feature" />
                  </FeatureImageWrapper>
                  </Link>
                  <FeatureName>{el.name}</FeatureName>
                  <FeatureText>{el.description}</FeatureText>
                  {/* <FeatureLinks
                spy={true}
                duration={500}
                smooth={true}
                exact="true"
                offset={-80}
                to={el.link}
              >{el.link}</FeatureLinks> */}
                </FeatureColumn>
              // </div>
            ))}
          </FeatureWrapper>
        </Container>
      </FeatureSection>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <div
          style={{
            background: "#0d0d0d",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: '2rem'
          }}
        >
          <FeatureImageWrapper>
            <FeatureImage
              src={featuresData.content[selectedImage].image}
              alt="feature"
            />
          </FeatureImageWrapper>
          <FeatureTitle>
            {featuresData.content[selectedImage].name}
          </FeatureTitle>
          <FeatureMainText>
            {featuresData.content[selectedImage].description}
          </FeatureMainText>
          <Link to={featuresData.content[selectedImage].link}>
          <a
                target="_blank"
                href={"#"}
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                <div className="heroneon">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  Visit Page
                </div>
              </a>
              
              </Link>
        </div>
      </Modal>
    </div>
  );
};

export default Features;
